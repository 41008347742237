<template>
  <div class="purchase-method-detail-base-info">
    <div class="info-block">
      <div class="info-item">
        <div class="label">文件名称：</div>
        <div class="value">{{data.title}}</div>
      </div>
    </div>
    <div class="info-block">
      <div class="info-item">
        <div class="label">类型：</div>
        <div class="value">{{data.type}}</div>
      </div>
      <div class="info-item">
        <div class="label">发文机构：</div>
        <div class="value">{{data.orgName}}</div>
      </div>
      <div class="info-item">
        <div class="label">申请人：</div>
        <div class="value">{{data.userName}}</div>
      </div>
      <div class="info-item" v-if="showSendCode">
        <div class="label">发文号：</div>
        <div class="value">{{data.sendCode}}</div>
      </div>
      <div class="info-item">
        <div class="label">发文份数：</div>
        <div class="value">{{data.num}}</div>
      </div>
      <div class="info-item">
        <div class="label">发文时间：</div>
        <div class="value">{{data.sendTime ? data.sendTime.slice(0, 10) : ''}}</div>
      </div>
      <div class="info-item">
        <div class="label">归入案卷号：</div>
        <div class="value">{{data.getCode}}</div>
      </div>
    </div>
    <div class="info-block">
      <div class="info-item">
        <div class="label">备注：</div>
        <div class="value">{{data.remark}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    showSendCode: { type: Boolean, defualt: false },
    data: { type: Object, defualt: () => null }
  },
  computed: {
    purchaseBatchDetailList () {
      return this.data && this.data.purchaseBatchDetailList ? this.data.purchaseBatchDetailList : []
    }
  }
}
</script>

<style lang="less" scoped>
.purchase-method-detail-base-info {
  color: #515a6e;
  .info-block {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .detail-info {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #e8eaec;
  }
  .info-item {
    display: flex;
    margin-right: 50px;
    margin-bottom: 5px;
    .label {
      margin-right: 0px;
      white-space: nowrap;
    }
  }
}
</style>